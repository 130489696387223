package com.rikonardo.pluginscan.preprocessor.generated

fun checksList() = listOf<com.rikonardo.pluginscan.framework.types.Check>(
    com.rikonardo.pluginscan.checks.BukkitConsoleCommands(),
    com.rikonardo.pluginscan.checks.BukkitLoadPlugin(),
    com.rikonardo.pluginscan.checks.BukkitMultipleListeners(),
    com.rikonardo.pluginscan.checks.BukkitPermissionAttachments(),
    com.rikonardo.pluginscan.checks.BukkitSetOp(),
    com.rikonardo.pluginscan.checks.BytecodeManipulationLibraries(),
    com.rikonardo.pluginscan.checks.ClassFileNameEquality(),
    com.rikonardo.pluginscan.checks.ClassLoaderUsed(),
    com.rikonardo.pluginscan.checks.ClassTooLarge(),
    com.rikonardo.pluginscan.checks.DifferentJavaVersion(),
    com.rikonardo.pluginscan.checks.EmbeddedJar(),
    com.rikonardo.pluginscan.checks.JavaSourceName(),
    com.rikonardo.pluginscan.checks.ModuleInfoWithSuper(),
    com.rikonardo.pluginscan.checks.MultipleMainClasses(),
    com.rikonardo.pluginscan.checks.ReflectionMethodCall(),
    com.rikonardo.pluginscan.checks.RuntimeExec(),
    com.rikonardo.pluginscan.checks.RuntimeLoad(),
)
